import React, { useState } from "react";
import { Card, Modal, Button } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "../styles/BetCard.css";
import countryFlagEmoji from "country-flag-emoji";
import { deleteMatch } from "../services/match.service";

const BetCard = ({ matches, onEdit, onDelete }) => {
  const [, setError] = useState(null);
  const [displayedMatchesCount, setDisplayedMatchesCount] = useState(3);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const today = new Date();

    // Si la date fournie est celle d'aujourd'hui
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return (
        "Aujourd'hui à " +
        date.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })
      );
    }

    return (
      date.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }) +
      " à " +
      date.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })
    );
  };

  const handleDelete = (matchId) => {
    Modal.confirm({
      title: "Êtes-vous sûr de vouloir supprimer ce match et ses paris ?",
      okText: "Je suis sûr",
      cancelText: "Non, modifier",
      okButtonProps: {
        danger: true, // Cette propriété rend le texte du bouton blanc sur fond rouge
      },
      onOk: async () => {
        try {
          await deleteMatch(matchId);
          onDelete();
        } catch (error) {
          setError(error);
        }
      },
    });
  };

  const getBookmakerString = (bets) => {
    let bookmakerStrings = [];
    let bookmakers = {};

    bets.forEach((bet) => {
      if (!bookmakers[bet.bookmaker.surnom]) {
        bookmakers[bet.bookmaker.surnom] = {
          count: 0,
          boosted: false,
        };
      }

      bookmakers[bet.bookmaker.surnom].count++;
      if (bet.boosted_odds) {
        bookmakers[bet.bookmaker.surnom].boosted = true;
      }
    });

    Object.keys(bookmakers).forEach((bookmaker) => {
      if (bookmakers[bookmaker].boosted) {
        bookmakerStrings.push(`CB ${bookmaker}`);
      }

      let countWithoutBoosted =
        bookmakers[bookmaker].count - (bookmakers[bookmaker].boosted ? 1 : 0);
      if (countWithoutBoosted >= 1) {
        if (countWithoutBoosted > 1) {
          bookmakerStrings.push(`${bookmaker} x${countWithoutBoosted}`);
        } else {
          bookmakerStrings.push(bookmaker);
        }
      }
    });

    // Format the final string
    if (bookmakerStrings.length > 1) {
      let lastBookmaker = bookmakerStrings.pop();
      return bookmakerStrings.join(", ") + " & " + lastBookmaker;
    } else {
      return bookmakerStrings[0];
    }
  };

  const loadMore = () => {
    // Augmentez le nombre de matches affichés, par exemple, de 3 supplémentaires à chaque fois.
    setDisplayedMatchesCount(displayedMatchesCount + 3);
  };

  return (
    <div className="BetCard">
      {matches
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) // Trier par date de modification décroissante
        .slice(0, displayedMatchesCount)
        .map((match) => (
          <Card
            key={match.id}
            style={{ marginBottom: 20 }}
            className="item"
            title={match.isPublished ? "Publié" : "Brouillon"}
            actions={[
              ...(!match.isPublished
                ? [<EditOutlined key="edit" onClick={() => onEdit(match)} />]
                : []),
              <DeleteOutlined onClick={() => handleDelete(match.id)} />,
            ]}
          >
            <div>
              <div>
                <span role="img" aria-label="league">
                  {countryFlagEmoji.get(match.championship.country_id).emoji}{" "}
                  {match.championship.nom}
                </span>
              </div>
              <div>
                <span role="img" aria-label="match">
                  ⚽️ {match.home_team} vs. {match.away_team}
                </span>
              </div>
              <br />
              {match.bets.map((bet) => (
                <div key={bet.id}>
                  <span role="img" aria-label="bet">
                    ⚡️ {bet.bet_name} @ {bet.odds} - {bet.stake}€{" "}
                    {bet.free_bet && " FB"}
                  </span>
                </div>
              ))}
              <br />
              <div>
                <span role="img" aria-label="bookmaker">
                  📚 {getBookmakerString(match.bets)}
                </span>
              </div>
              <br />
              <div>#TeamParieur</div>
            </div>
            <div className="date">{formatDate(match.updatedAt)}</div>
          </Card>
        ))}
      {displayedMatchesCount < matches.length && (
        <Button
          block
          type="primary"
          onClick={loadMore}
          icon={<LoadingOutlined />}
        >
          Charger plus
        </Button>
      )}
    </div>
  );
};

export default BetCard;
