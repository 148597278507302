import api from "./api.service";
import getAuthHeader from "./auth.header.service";
import countryFlagEmoji from "country-flag-emoji";

export const fetchChampionships = async () => {
  try {
    const response = await api.get("/championships");
    const championshipsData = response.data;

    const sortedChampionships = championshipsData
      .map((championship) => ({
        ...championship,
        flag: countryFlagEmoji.get(championship.country_id).emoji,
      }))
      .sort((a, b) => a.nom.localeCompare(b.nom));

    return sortedChampionships;
  } catch (error) {
    console.error("Error fetching championships:", error);
    throw error; // Renvoie l'erreur pour être gérée par le composant appelant.
  }
};

export const addChampionship = async (values) => {
  try {
    await api.post("championships", values, { headers: getAuthHeader() });
  } catch (error) {
    console.error("Error adding championship:", error);
    throw error;
  }
};

export const deleteChampionship = async (id) => {
  try {
    await api.delete(`championships/${id}`, { headers: getAuthHeader() });
  } catch (error) {
    console.error("Error deleting championship:", error);
    throw error;
  }
};

export const updateChampionship = async (id, values) => {
  try {
    await api.put(`championships/${id}`, values, { headers: getAuthHeader() });
  } catch (error) {
    console.error("Error updating championship:", error);
    throw error;
  }
};
