import { useState, useEffect } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    // Ajoute un écouteur d'événement de redimensionnement de la fenêtre
    window.addEventListener("resize", handleResize);

    // Vérifie la taille de l'écran lors du montage du composant
    setIsMobile(window.innerWidth <= 576);

    // Nettoie l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
