import api from "./api.service";

class AuthService {
  async login(username, password) {
    try {
      const response = await api.post(`auth/signin`, {
        username,
        password,
      });

      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
      throw error;
    }
  }

  logout() {
    localStorage.removeItem("user");
  }

  async register(username, email, password) {
    try {
      const response = await api.post(`auth/signup`, {
        username,
        email,
        password,
      });
      return response.data;
    } catch (error) {
      console.error("Erreur lors de l'inscription:", error);
      throw error;
    }
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

const authService = new AuthService();
export default authService;
