import { Button, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const DeleteBetButton = ({ handleRemoveBet, isMobile }) => {
  return (
    <Tooltip title="Supprimer">
      <Button
        type="text"
        onClick={handleRemoveBet}
        icon={<DeleteOutlined />}
        style={{
          backgroundColor: "#ff4d4f",
          border: "none",
          color: "#fff",
          width: isMobile ? "100%" : undefined,
        }}
      >
        {isMobile && (
          <span style={{ marginRight: "4px" }}>Supprimer le bet</span>
        )}
      </Button>
    </Tooltip>
  );
};

export default DeleteBetButton;
