import api from "./api.service";
import getAuthHeader from "./auth.header.service";
import { message } from "antd";

export const saveBet = async (matchExists, matchId, payload) => {
  try {
    if (matchExists) {
      await api.put(`/matches_with_bets/${matchId}`, payload, {
        headers: getAuthHeader(),
      });
    } else {
      await api.post("/matches_with_bets", payload, {
        headers: getAuthHeader(),
      });
    }
    return true;
  } catch (error) {
    message.error("Erreur lors de l'envoi des données.");
    console.error(error);
    return false;
  }
};

export const sendBet = async (matchExists, matchId, payload) => {
  try {
    if (matchExists) {
      await api.put(`/matches_with_bets/${matchId}`, payload, {
        headers: getAuthHeader(),
      });
    } else {
      await api.post("/matches_with_bets", payload, {
        headers: getAuthHeader(),
      });
    }
    return true;
  } catch (error) {
    message.error("Erreur lors de l'envoi des données.");
    console.error(error);
    return false;
  }
};
