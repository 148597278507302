import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { fetchChampionships } from "../services/championship.service";
const { Option } = Select;

const ChampionshipSelector = ({ setChampionship, initialValue }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const championships = await fetchChampionships();
        setData(championships);
      } catch (error) {
        console.error("Error fetching championships in component:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (value) => {
    const selectedChampionship = data.find((item) => item.nom === value);
    setChampionship(selectedChampionship); // Passons l'objet complet
  };

  return (
    <Select
      defaultValue={initialValue}
      showSearch
      style={{ width: "100%" }}
      placeholder="Choisir une compétition"
      optionFilterProp="children"
      onChange={handleChange}
      filterOption={(input, option) => {
        const searchText = input.toLowerCase();
        const { children, value, "data-country": country } = option.props;
        const formattedChildren =
          typeof children === "string" ? children.toLowerCase() : "";
        const formattedValue =
          typeof value === "string" ? value.toLowerCase() : "";
        const formattedCountry =
          typeof country === "string" ? country.toLowerCase() : "";

        return (
          formattedChildren.includes(searchText) ||
          formattedValue.includes(searchText) ||
          formattedCountry.includes(searchText)
        );
      }}
    >
      {data.map((item) => (
        <Option key={item.id} value={item.nom} data-country={item.country}>
          {item.flag} {item.nom}
        </Option>
      ))}
    </Select>
  );
};

export default ChampionshipSelector;
