import React, { useEffect } from "react";
import { Modal, Form, Button } from "antd";

// Dans le composant GenericModal
const GenericModal = ({
  title,
  open,
  onCancel,
  onFinish,
  formFields,
  initialValues,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleFinish = (values) => {
    onFinish(values);
    form.resetFields();
  };

  const handleClose = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <Modal title={title} open={open} onCancel={handleClose} footer={null}>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        {formFields.map((field) => (
          <Form.Item
            key={field.name}
            name={field.name}
            label={field.label}
            rules={field.rules}
          >
            {field.component}
          </Form.Item>
        ))}
        <Button type="primary" htmlType="submit">
          {title}
        </Button>
      </Form>
    </Modal>
  );
};

export default GenericModal;
