import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import MainApp from "./pages/MainApp";
import AdminPage from "./pages/AdminPage";
import PrivateRoute from "./routes/PrivateRoute";
import AuthMiddleware from "./middleware/AuthMiddleware";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <AuthMiddleware>
              <MainApp />
            </AuthMiddleware>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/admin/*"
          element={
            <AuthMiddleware>
              <PrivateRoute>
                <AdminPage />
              </PrivateRoute>
            </AuthMiddleware>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
