import React, { useState, useEffect } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  UserOutlined,
  LeftOutlined,
  LogoutOutlined,
  TrophyOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../services/user.service";
import authService from "../services/auth.service";
import AccountForm from "../components/AccountForm";
import ChampionshipsPage from "../components/ChampionshipsForm";
import BookmakersPage from "../components/BookmakersForm";

const { Sider, Content, Footer } = Layout;

const AdminPage = () => {
  const [selectedKey, setSelectedKey] = useState("account");
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // Utilisation de useNavigate pour la redirection

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const userData = await fetchUser();
        if (userData) {
          setUser(userData);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'utilisateur:",
          error
        );
      }
    };

    loadUserData();
  }, []);

  const handleMenuSelect = (item) => {
    switch (item.key) {
      case "account":
        navigate("/admin/account");
        break;
      case "championships":
        navigate("/admin/championships");
        break;
      case "bookmakers":
        navigate("/admin/bookmakers");
        break;
      case "retour":
        handleGoToRoot();
        break;
      case "logout":
        handleLogout();
        break;
      default:
        break;
    }

    setSelectedKey(item.key);
  };

  const handleLogout = () => {
    authService.logout();
    navigate("/login");
  };

  const handleAccountFormSubmit = (updatedUser) => {
    setUser(updatedUser);
  };

  const handleGoToRoot = () => {
    navigate("/"); // Redirection vers la racine de l'application
  };

  const menuItems = [
    {
      key: "account",
      icon: <UserOutlined />,
      label: "Mon compte",
    },
    {
      key: "championships",
      label: "Championnats",
      icon: <TrophyOutlined />,
    },
    {
      key: "bookmakers",
      label: "Bookmakers",
      icon: <DollarOutlined />,
    },
    {
      key: "retour",
      icon: <LeftOutlined />,
      label: "Retour créateur",
    },
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: "Déconnexion",
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider breakpoint="lg" collapsedWidth="0">
        <div style={{ margin: "16px", textAlign: "center", color: "#fff" }}>
          <h2>BetTweetr</h2>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          items={menuItems}
          selectedKeys={[selectedKey]}
          onSelect={handleMenuSelect}
        />
      </Sider>
      <Layout>
        <Content style={{ margin: "16px", borderRadius: "6px" }}>
          <Routes>
            <Route
              path="account"
              element={
                <AccountForm user={user} onFinish={handleAccountFormSubmit} />
              }
            />
            <Route path="/" element={<Navigate to="/admin/account" />} />
            <Route path="championships" element={<ChampionshipsPage />} />
            <Route path="bookmakers" element={<BookmakersPage />} />
            <Route path="*" element={<Outlet />} />{" "}
          </Routes>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          BetTweetr ©2023 Created by Mathieu Esteves
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AdminPage;
