import React, { useState, useEffect } from "react";
import { Button, Table, Space, Input, Tooltip, message, Modal } from "antd";
import { PlusOutlined, EditFilled, DeleteFilled } from "@ant-design/icons";
import GenericModal from "./AdminPageComponents/GenericModal";
import AdminPageTemplate from "./AdminPageTemplate";
import {
  fetchBookmakers,
  addBookmaker,
  deleteBookmaker,
  updateBookmaker,
} from "../services/bookmaker.service";

const BookmakersPage = () => {
  const [bookmakers, setBookmakers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editedBookmaker, setEditedBookmaker] = useState(null);

  useEffect(() => {
    // Fetch les bookmakers au montage du composant
    fetchBookmakers()
      .then((data) => {
        setBookmakers(data);
      })
      .catch((error) => {
        console.error("Error fetching bookmakers:", error);
      });
  }, []);

  const onAddBookmaker = (values) => {
    addBookmaker(values)
      .then((updatedBookmakers) => {
        setBookmakers(updatedBookmakers);
        message.success("Bookmaker créé !");
        closeEditModal();
      })
      .catch((error) => {
        console.error("Error adding bookmaker:", error);
      });
  };

  const onDeleteBookmaker = (id) => {
    Modal.confirm({
      title: "Confirmer la suppression",
      content: "Êtes-vous sûr de vouloir supprimer ce bookmaker ?",
      okText: "Oui",
      cancelText: "Non",
      async onOk() {
        try {
          await deleteBookmaker(id);
          message.success("Bookmaker supprimé ");

          // Fetch the updated list of bookmakers after successful deletion
          const updatedBookmakers = await fetchBookmakers();
          setBookmakers(updatedBookmakers); // Assuming you have a state named 'bookmakers' and a setter 'setBookmakers'
        } catch (error) {
          message.error("Impossible de supprimer le bookmaker");
        }
      },
    });
  };

  const onUpdateBookmaker = (id, values) => {
    // Trouver le bookmaker actuel par ID
    const currentBookmaker = bookmakers.find((b) => b.id === id);

    // Vérifier si les valeurs sont identiques
    if (
      currentBookmaker.nom === values.nom &&
      currentBookmaker.surnom === values.surnom
    ) {
      message.info("Bookmaker déjà à jour");
      closeEditModal();
      return; // Ne pas procéder à la mise à jour
    }

    updateBookmaker(id, values)
      .then((updatedBookmakers) => {
        setBookmakers(updatedBookmakers);
        message.success("Bookmaker mis à jour !");
        closeEditModal();
      })
      .catch((error) => {
        message.error("Impossible de mettre à jour le bookmaker");
      });
  };

  const openEditModal = (bookmaker) => {
    setEditedBookmaker(bookmaker);
    setModalVisible(true);
  };

  const closeEditModal = () => {
    setEditedBookmaker(null);
    setModalVisible(false);
  };

  const breadcrumbItems = ["Accueil", "Administration", "Bookmakers"];

  const formFields = [
    {
      name: "nom",
      label: "Nom",
      rules: [{ required: true, message: "Le nom du bookmaker est requis" }],
      component: <Input />,
    },
    {
      name: "surnom",
      label: "Surnom",
      rules: [
        {
          required: true,
          message: "Le surnom du bookmaker est requis",
        },
      ],
      component: <Input />,
    },
  ];

  const columns = [
    {
      title: "Nom",
      dataIndex: "nom",
      key: "nom",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      title: "Surnom",
      dataIndex: "surnom",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Modifier">
            <Button
              type="link"
              onClick={() => {
                openEditModal(record);
              }}
              className="edit-button"
              icon={<EditFilled />}
              style={{
                backgroundColor: "#444343",
                border: "none",
                color: "#fff",
              }}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              type="link"
              onClick={() => onDeleteBookmaker(record.id)}
              className="delete-button"
              icon={<DeleteFilled />}
              style={{
                backgroundColor: "#ff4d4f",
                border: "none",
                color: "#fff",
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const content = (
    <>
      <Button
        type="primary"
        block
        onClick={() => setModalVisible(true)}
        style={{ marginBottom: "20px" }}
        icon={<PlusOutlined />}
      >
        Ajouter un bookmaker
      </Button>

      <GenericModal
        title={editedBookmaker ? "Modifier" : "Ajouter"}
        open={modalVisible}
        onCancel={() => closeEditModal()}
        onFinish={
          editedBookmaker
            ? (values) => onUpdateBookmaker(editedBookmaker.id, values)
            : onAddBookmaker
        }
        formFields={formFields}
        initialValues={editedBookmaker} // Utilisez editedBookmaker directement
      />

      <Table
        rowKey="id"
        columns={columns}
        dataSource={bookmakers}
        pagination={{
          pageSize: 10,
          position: ["bottomCenter"],
        }}
      />
    </>
  );

  return (
    <AdminPageTemplate breadcrumbItems={breadcrumbItems} content={content} />
  );
};

export default BookmakersPage;
