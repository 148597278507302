import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Collapse, Checkbox } from "antd";

const { Panel } = Collapse;

const BetCollapseOption = ({ panels }) => {
  return (
    <Collapse
      bordered={false}
      style={{ marginTop: "10px", width: "100%" }}
      expandIcon={({ isActive }) =>
        isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />
      }
    >
      {panels.map((panel, index) => (
        <Panel key={index} header={panel.label} showArrow={true}>
          <div style={{ padding: "16px" }}>
            <div style={{ marginTop: "10px" }}>
              {panel.options.map((option, optionIndex) => (
                <div
                  key={optionIndex}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Checkbox
                    checked={option.isChecked}
                    onChange={option.handleChange}
                  >
                    {option.label} {option.icon}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
        </Panel>
      ))}
    </Collapse>
  );
};

export default BetCollapseOption;
