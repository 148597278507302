import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { fetchBookmakers } from "../services/bookmaker.service";

const { Option } = Select;

const BookmakerSelector = ({ setBookmaker, initialValue }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadBookmakers = async () => {
      try {
        const bookmakers = await fetchBookmakers();
        setData(bookmakers);
      } catch (error) {
        console.error(error);
      }
    };
    loadBookmakers();
  }, []);

  const handleChange = (value) => {
    const selectedBookmaker = data.find((item) => item.id === value);
    setBookmaker(selectedBookmaker);
  };

  const filterOption = (input, option) => {
    if (typeof option.children === "string") {
      return option.children.toLowerCase().startsWith(input.toLowerCase());
    }
    return false;
  };

  return (
    <Select
      virtual
      defaultValue={initialValue || undefined}
      placeholder="Bookmaker"
      onChange={handleChange}
      filterOption={filterOption}
      style={{ width: "100%" }}
      showSearch
    >
      {data.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.nom}
        </Option>
      ))}
    </Select>
  );
};

export default BookmakerSelector;
