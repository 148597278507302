import React, { useEffect, useState } from "react";
import { Form, Input, Button, Spin } from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import { updateAccountDetails } from "../services/user.service";
import PasswordModal from "./PasswordModal";

const AccountForm = ({
  onFinish,
  user,
  myOnFinishFunction,
  mySetEditingFieldFunction,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingField, setEditingField] = useState(null);

  useEffect(() => {
    if (user) {
      setName(user.username);
      setEmail(user.email);
      setIsLoading(false);
    }
  }, [user]);

  const handleAccountFormSubmit = (values) => {
    updateAccountDetails(values, myOnFinishFunction, mySetEditingFieldFunction);
  };

  const handleEditClick = (fieldName) => {
    setEditingField(fieldName);
  };

  const handleCancelClick = () => {
    setEditingField(null);
  };

  const isFieldEditable = (fieldName) => {
    return editingField === fieldName;
  };

  const handleFieldChange = (fieldName, value) => {
    if (fieldName === "name") {
      setName(value);
    } else if (fieldName === "email") {
      setEmail(value);
    }
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <div style={{ background: "#fff", padding: "24px", borderRadius: "4px" }}>
      <h3 style={{ marginBottom: "24px" }}>
        Modifier les informations du compte
      </h3>
      <Form onFinish={handleAccountFormSubmit} layout="vertical">
        <Form.Item>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              prefix={<UserOutlined />}
              placeholder="Nom"
              value={name}
              readOnly={!isFieldEditable("name")}
              onChange={(e) => handleFieldChange("name", e.target.value)}
            />
            {!isFieldEditable("name") ? (
              <Button
                type="link"
                htmlType="button"
                onClick={() => handleEditClick("name")}
                style={{ marginLeft: "8px" }}
              >
                Modifier
              </Button>
            ) : (
              <>
                <Button
                  type="link"
                  htmlType="button"
                  onClick={handleCancelClick}
                  style={{ marginLeft: "8px" }}
                >
                  Annuler
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "8px" }}
                >
                  Sauvegarder
                </Button>
              </>
            )}
          </div>
        </Form.Item>
        <Form.Item>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              prefix={<MailOutlined />}
              placeholder="Adresse e-mail"
              value={email}
              readOnly={!isFieldEditable("email")}
              onChange={(e) => handleFieldChange("email", e.target.value)}
            />
            {!isFieldEditable("email") ? (
              <Button
                type="link"
                htmlType="button"
                onClick={() => handleEditClick("email")}
                style={{ marginLeft: "8px" }}
              >
                Modifier
              </Button>
            ) : (
              <>
                <Button
                  type="link"
                  htmlType="button"
                  onClick={handleCancelClick}
                  style={{ marginLeft: "8px" }}
                >
                  Annuler
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "8px" }}
                >
                  Sauvegarder
                </Button>
              </>
            )}
          </div>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Changer le mot de passe
          </Button>
          <PasswordModal
            user={user}
            isVisible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            onFinish={onFinish}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AccountForm;
