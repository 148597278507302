import api from "./api.service";
import getAuthHeader from "./auth.header.service";
import { message } from "antd";

const fetchMatches = async () => {
  try {
    const response = await api.get("/matches_with_bets/", {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (err) {
    console.error("Error fetching matches with bets:", err);
    throw err; // nous propageons l'erreur pour la gérer à l'extérieur
  }
};

const deleteMatch = async (matchId) => {
  try {
    await api.delete(`/matches_with_bets/${matchId}`, {
      headers: getAuthHeader(),
    });
    message.success("Match supprimé avec succès!");
  } catch (error) {
    console.error("Erreur lors de la suppression du match:", error);
    message.error("Erreur lors de la suppression du match.");
    throw error; // nous propageons l'erreur pour la gérer à l'extérieur
  }
};

export { fetchMatches, deleteMatch };
