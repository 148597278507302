import React, { useState, useEffect } from "react";
import { Button, Table, Space, Input, Modal, Tooltip, message } from "antd";
import { PlusOutlined, EditFilled, DeleteFilled } from "@ant-design/icons";
import GenericModal from "./AdminPageComponents/GenericModal";
import AdminPageTemplate from "./AdminPageTemplate";
import {
  fetchChampionships,
  addChampionship,
  deleteChampionship,
  updateChampionship,
} from "../services/championship.service";

const ChampionshipsPage = () => {
  const [championships, setChampionships] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editedChampionship, setEditedChampionship] = useState(null);

  useEffect(() => {
    fetchChampionships();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchChampionships();
        setChampionships(data);
      } catch (error) {
        console.error("Error fetching championships in component:", error);
      }
    };

    fetchData();
  }, []);

  const handleAdd = async (values) => {
    try {
      await addChampionship(values);
      message.success("Championnat ajouté !");
      const updatedChampionships = await fetchChampionships();
      setChampionships(updatedChampionships);
      setModalVisible(false);
    } catch (error) {
      message.error("Impossible d'ajouter un championnat");
    }
  };

  const onDeleteChampionship = (id) => {
    Modal.confirm({
      title: "Confirmer la suppression",
      content: "Êtes-vous sûr de vouloir supprimer ce championnat ?",
      okText: "Oui",
      cancelText: "Non",
      async onOk() {
        try {
          await deleteChampionship(id);
          message.success("Championnat supprimé !");

          // Fetch the updated list of championships after successful deletion
          const updatedChampionships = await fetchChampionships();
          setChampionships(updatedChampionships); // Assuming you have a state named 'championships' and a setter 'setChampionships'
        } catch (error) {
          message.error("Impossible de supprimer le championnat");
        }
        setModalVisible(false);
      },
    });
  };

  const onUpdateChampionship = (id, values) => {
    // Trouver le championship actuel par ID
    const currentChampionship = championships.find((b) => b.id === id);

    // Vérifier si les valeurs sont identiques
    if (
      currentChampionship.nom === values.nom &&
      currentChampionship.surnom === values.surnom
    ) {
      message.info("Championnat déjà à jour");
      closeEditModal();
      return; // Ne pas procéder à la mise à jour
    }

    updateChampionship(id, values)
      .then((updatedChampionships) => {
        setChampionships(updatedChampionships);
        message.success("Championnat mis à jour !");
        closeEditModal();
      })
      .catch((error) => {
        message.error("Impossible de mettre à jour le championnat");
      });
  };

  const openEditModal = (championship) => {
    setEditedChampionship(championship);
    setModalVisible(true);
  };

  const closeEditModal = () => {
    setEditedChampionship(null);
    setModalVisible(false);
  };

  const breadcrumbItems = ["Accueil", "Administration", "Championnats"];

  const formFields = [
    {
      name: "nom",
      label: "Nom",
      rules: [{ required: true, message: "Le nom du championnat est requis" }],
      component: <Input />,
    },
    {
      name: "country",
      label: "Pays",
      rules: [{ required: true, message: "Le nom du pays est requis" }],
      component: <Input />,
    },
    {
      name: "country_id",
      label: "ID Pays",
      rules: [
        {
          required: true,
          message: "L'id du pays est requis",
        },
      ],
      component: <Input />,
    },
  ];

  const columns = [
    {
      title: "Nom",
      dataIndex: "nom",
      key: "nom",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      title: "Pays",
      dataIndex: "country",
      key: "country",
      sorter: (a, b) => a.country.localeCompare(b.country),
    },
    {
      title: "ID Pays",
      dataIndex: "country_id",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Modifier">
            <Button
              type="link"
              onClick={() => {
                openEditModal(record);
              }}
              className="edit-button"
              icon={<EditFilled />}
              style={{
                backgroundColor: "#444343",
                border: "none",
                color: "#fff",
              }}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              type="link"
              onClick={() => onDeleteChampionship(record.id)}
              className="delete-button"
              icon={<DeleteFilled />}
              style={{
                backgroundColor: "#ff4d4f",
                border: "none",
                color: "#fff",
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const content = (
    <>
      <Button
        type="primary"
        block
        onClick={() => setModalVisible(true)}
        style={{ marginBottom: "20px" }}
        icon={<PlusOutlined />}
      >
        Ajouter un championnat
      </Button>

      <GenericModal
        title={editedChampionship ? "Modifier" : "Ajouter"}
        open={modalVisible}
        onCancel={() => closeEditModal()}
        onFinish={
          editedChampionship
            ? (values) => onUpdateChampionship(editedChampionship.id, values)
            : handleAdd
        }
        formFields={formFields}
        initialValues={editedChampionship} // Utilisez editedChampionship directement
      />

      <Table
        rowKey="id"
        columns={columns}
        dataSource={championships}
        pagination={{
          pageSize: 10,
          position: ["bottomCenter"],
        }}
      />
    </>
  );

  return (
    <AdminPageTemplate breadcrumbItems={breadcrumbItems} content={content} />
  );
};

export default ChampionshipsPage;
