import React from "react";
import { Row, Col, Form, Input, Button, Layout } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";

const { Content } = Layout;

const LoginPage = () => {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const response = await AuthService.login(values.email, values.password);

      // Traitez la réponse du serveur ici
      console.log("Login success:", response);

      // Redirigez l'utilisateur vers la page d'administration ou une autre page après la connexion réussie
      navigate("/admin"); // Remplacez '/admin' par le chemin vers votre page d'administration
    } catch (error) {
      // Gérez les erreurs de l'appel API
      console.error("Login failed:", error);
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content>
        <Row justify="center" align="middle" style={{ height: "100%" }}>
          <Col span={8}>
            <div style={{ textAlign: "center", marginBottom: 24 }}>
              <h2>Login to BetTweetr</h2>
            </div>
            <Form onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Veuillez entrer votre nom d'utilisateur.",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder="Nom d'utilisateur"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Veuillez entrer votre mot de passe.",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  <UserOutlined /> Login
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default LoginPage;
