import api from "./api.service";
import getAuthHeader from "./auth.header.service";
import { message } from "antd";

export const fetchUser = async () => {
  try {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const response = await api.get(`/user/${currentUser.id}`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateUserProfile = async (userId, values) => {
  try {
    const response = await api.get(`/user/${userId}`, {
      headers: getAuthHeader(),
    });
    const userData = response.data;

    if (
      values.currentPassword &&
      values.currentPassword !== userData.password
    ) {
      message.error("Mot de passe actuel incorrect");
      return;
    }

    const updateData = {};

    if (values.name) {
      updateData.username = values.name;
    }

    if (values.email) {
      updateData.email = values.email;
    }

    if (values.newPassword) {
      updateData.password = values.newPassword;
    }

    const updateResponse = await api.put(`/user/${userId}`, updateData, {
      headers: getAuthHeader(),
    });

    message.success("Informations utilisateur mises à jour avec succès");
    return updateResponse.data;
  } catch (error) {
    message.error(
      "Une erreur s'est produite lors de la mise à jour des informations utilisateur."
    );
    console.error(error);
  }
};

export const updateAccountDetails = async (
  values,
  onFinish,
  setEditingField
) => {
  try {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const response = await api.get(`/user/${currentUser.id}`, {
      headers: getAuthHeader(),
    });
    const userData = response.data;

    // Vérification du mot de passe actuel s'il est fourni
    if (
      values.currentPassword &&
      values.currentPassword !== userData.password
    ) {
      message.error("Mot de passe actuel incorrect");
      return;
    }

    // Les mots de passe correspondent, continuez avec la mise à jour
    const updateData = {};

    if (values.name) {
      updateData.username = values.name;
    }

    if (values.email) {
      updateData.email = values.email;
    }

    if (values.newPassword) {
      updateData.password = values.newPassword;
    }

    // Effectuez la requête PUT pour mettre à jour les informations de l'utilisateur
    const updateResponse = await api.put(
      `/user/${currentUser.id}`,
      updateData,
      {
        headers: getAuthHeader(),
      }
    );

    message.success("Informations utilisateur mises à jour avec succès");
    onFinish(updateResponse.data);
    setEditingField(null);
  } catch (error) {
    message.error(
      "Une erreur s'est produite lors de la mise à jour des informations utilisateur."
    );
    console.error(error);
  }
};

export const updatePassword = async (userId, currentPassword, newPassword) => {
  try {
    const response = await api.get(`/user/${userId}`, {
      headers: getAuthHeader(),
    });
    const userData = response.data;

    if (currentPassword && currentPassword !== userData.password) {
      message.error("Mot de passe actuel incorrect");
      return null;
    }

    const updateData = { password: newPassword };

    const updateResponse = await api.put(`/user/${userId}`, updateData, {
      headers: getAuthHeader(),
    });

    message.success("Mot de passe mis à jour avec succès");
    return updateResponse.data;
  } catch (error) {
    message.error(
      "Une erreur s'est produite lors de la mise à jour du mot de passe."
    );
    console.error(error);
    return null;
  }
};
