import React, { useState } from "react";
import { Button, Modal, message } from "antd";
import { TwitterOutlined, SendOutlined, SaveOutlined } from "@ant-design/icons";
import useIsMobile from "../services/useIsMobile";
import { saveBet, sendBet } from "../services/bets.service";

function TweetButton({
  flag,
  championship,
  championshipId,
  bookmakers,
  bookmakerIds,
  teams,
  bets,
  resetInputs,
  matchExists,
  matchId,
  updateMatches,
}) {
  const [tweet, setTweet] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isMobile = useIsMobile();

  const generateTweetContent = () => {
    let tweetContent = `${flag} ${championship.trim()}<br />`;
    tweetContent += `⚽️ ${teams.team1.trim()} vs. ${teams.team2.trim()}<br /><br />`;

    for (let i = 0; i < bets.length; i++) {
      const { bet, odds, stake } = bets[i];
      let stakeContent = `${stake}€`;
      if (bets[i].isFreeBetActive) {
        stakeContent += " FB";
      }
      tweetContent += `⚡️ ${bet.trim()} @ ${odds} - ${stakeContent}<br />`;
    }

    if (bookmakers && bookmakers.length > 0) {
      const uniqueBookmakers = [];
      const bookmakerCounts = {};

      for (let i = 0; i < bookmakers.length; i++) {
        const bookmakerName = bets[i].isBoostedOdd
          ? `CB ${bookmakers[i]}`
          : bookmakers[i];
        if (!bookmakerCounts[bookmakerName]) {
          bookmakerCounts[bookmakerName] = 1;
        } else {
          bookmakerCounts[bookmakerName]++;
        }
      }

      for (const bookmaker in bookmakerCounts) {
        const count = bookmakerCounts[bookmaker];
        if (count === 1) {
          uniqueBookmakers.push(bookmaker);
        } else {
          uniqueBookmakers.push(`${bookmaker} x${count}`);
        }
      }

      let formattedBookmakers = "";
      if (uniqueBookmakers.length === 1) {
        formattedBookmakers = uniqueBookmakers[0];
      } else if (uniqueBookmakers.length === 2) {
        formattedBookmakers = `${uniqueBookmakers[0]} & ${uniqueBookmakers[1]}`;
      } else {
        formattedBookmakers = `${uniqueBookmakers
          .slice(0, -1)
          .join(", ")} & ${uniqueBookmakers.slice(-1)}`;
      }

      tweetContent += `<br />📚 ${formattedBookmakers}<br /><br />#TeamParieur`;
    } else {
      tweetContent += `<br />📚 Aucun book selectionné<br /><br />#TeamParieur`;
    }

    return tweetContent;
  };

  const handleButtonClick = () => {
    const tweetContent = generateTweetContent();
    if (tweetContent.length > 280) {
      message.error("Le tweet excède les 280 caractères!");
    } else {
      setTweet(tweetContent);
      setIsModalVisible(true);
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const generatePayload = (teams, championshipId, bets, bookmakerIds) => {
    return {
      matchDetails: {
        home_team: teams.team1,
        away_team: teams.team2,
        championship_id: championshipId,
      },
      bets: bets.map((bet, index) => ({
        id: bet.id || null,
        bet_name: bet.bet,
        odds: parseFloat(bet.odds),
        stake: parseFloat(bet.stake),
        bookmaker_id: bookmakerIds[index],
        boosted_odds: bet.isBoostedOdd,
        free_bet: bet.isFreeBetActive,
      })),
    };
  };

  const handleSaveTweet = async () => {
    const payload = generatePayload(teams, championshipId, bets, bookmakerIds);
    const isPublished = false;
    payload.matchDetails.isPublished = isPublished;

    const success = await saveBet(matchExists, matchId, payload);
    if (success) {
      setIsModalVisible(false);
      message.success("Tweet sauvegardé !");
      resetInputs();
      updateMatches();
    }
  };

  const handleSendTweet = async () => {
    const payload = generatePayload(teams, championshipId, bets, bookmakerIds);
    const isPublished = true;
    payload.matchDetails.isPublished = isPublished;

    const success = await sendBet(matchExists, matchId, payload);
    if (success) {
      const formattedTweet = tweet.replace(/<br \/>/g, "\n");
      const tweetUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(
        formattedTweet
      )}`;

      window.location.href = tweetUrl;

      setIsModalVisible(false);
      message.success("Tweet envoyé !");
      resetInputs();
      updateMatches();
    }
  };

  // ...

  return (
    <>
      <Button
        type="primary"
        block
        onClick={handleButtonClick}
        icon={<TwitterOutlined />}
        style={{
          width: isMobile ? "100%" : undefined,
          backgroundColor: "#1DA1F2",
        }}
        className="antd-btn-icon-only"
      >
        Créer le tweet
      </Button>
      <Modal
        title="Tweet Preview"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="save" onClick={handleSaveTweet} icon={<SaveOutlined />}>
            Sauvegarder
          </Button>,
          <Button
            key="send"
            type="primary"
            onClick={handleSendTweet}
            icon={<SendOutlined />}
          >
            Envoyer
          </Button>,
        ]}
      >
        <div dangerouslySetInnerHTML={{ __html: tweet }}></div>
      </Modal>
    </>
  );
}

export default TweetButton;
