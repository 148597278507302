import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Row, Col, Layout, Modal } from "antd";
import {
  PlusOutlined,
  SettingOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import ChampionshipSelector from "../components/ChampionshipSelector";
import TeamInput from "../components/TeamInput";
import BetInput from "../components/BetInput";
import TweetButton from "../components/TweetButton";
import BetCard from "../components/BetCard";
import useIsMobile from "../services/useIsMobile";
import { Helmet } from "react-helmet";
import { message, Spin } from "antd";
import countryFlagEmoji from "country-flag-emoji";
import { fetchMatches } from "../services/match.service";

const { Header, Content } = Layout;

const MainApp = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [matchExists, setMatchExists] = useState(false);
  const [matchId, setMatchId] = useState(null);
  const [matches, setMatches] = useState([]);
  const [championship, setChampionship] = useState({});
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [bets, setBets] = useState([
    {
      bet: "",
      odds: "",
      stake: "",
      bookmaker: {
        id: "",
        nom: "",
        surnom: "",
      },
      isFreeBetActive: false,
      isBoostedOdd: false,
    },
  ]);
  const [form] = Form.useForm();
  const isMobile = useIsMobile();
  const [isFreeBetActive] = useState(false);
  const [isBoostedOdd] = useState(false);
  const [panelStates, setPanelStates] = useState([]);
  const [championshipFlag, setChampionshipFlag] = useState(null);
  const [loadingMatches, setLoadingMatches] = useState(true);

  useEffect(() => {
    updateMatches();
  }, []);

  const handleChampionshipChange = (selectedChampionship) => {
    setChampionship({
      nom: selectedChampionship.nom,
      id: selectedChampionship.id,
    });
    setChampionshipFlag(selectedChampionship.flag);
  };

  const handleTeamChange = (teamName, event) => {
    if (teamName === "team1") {
      setTeam1(event.target.value);
    } else if (teamName === "team2") {
      setTeam2(event.target.value);
    }
  };

  const handleAddBet = () => {
    setBets([
      ...bets,
      {
        bet: "",
        odds: "",
        stake: "",
        bookmaker: "",
        isFreeBetActive: false,
        isBoostedOdd: false,
      },
    ]);
  };

  const handleBetChange = (index, value) => {
    const updatedBets = [...bets];
    updatedBets[index].bet = value;
    setBets(updatedBets);
  };

  const handleOddsChange = (index, value) => {
    const updatedBets = [...bets];
    updatedBets[index].odds = value;
    setBets(updatedBets);
  };

  const handleStakeChange = (index, value) => {
    const updatedBets = [...bets];
    updatedBets[index].stake = value;
    setBets(updatedBets);
  };

  const handleBookmakerChange = (index, selectedBookmaker) => {
    setIsEditing(false);
    const updatedBets = [...bets];
    updatedBets[index].bookmaker = {
      nom: selectedBookmaker.nom,
      surnom: selectedBookmaker.surnom,
      id: selectedBookmaker.id,
    };
    setBets(updatedBets);
  };

  const handleRemoveBet = (index) => {
    const updatedBets = [...bets];
    updatedBets.splice(index, 1);
    setBets(updatedBets);
  };

  const handleFreeBetToggle = (index) => {
    const updatedBets = [...bets];
    updatedBets[index].isFreeBetActive = !updatedBets[index].isFreeBetActive;
    setBets(updatedBets);
  };

  const handleBoostedOdd = (index) => {
    const updatedBets = [...bets];
    updatedBets[index].isBoostedOdd = !updatedBets[index].isBoostedOdd;
    setBets(updatedBets);
  };

  const handleEdit = (match) => {
    resetInputs();

    let flag = countryFlagEmoji.get(match.championship.country_id).emoji;
    setChampionship({
      nom: match.championship.nom,
      id: match.championship.id,
    });
    setChampionshipFlag(flag);
    setTeam1(match.home_team);
    setTeam2(match.away_team);
    setBets(
      match.bets.map((bet) => ({
        id: bet.id,
        bet: bet.bet_name,
        odds: bet.odds.toString(),
        stake: bet.stake.toString(),
        isFreeBetActive: bet.free_bet,
        isBoostedOdd: bet.boosted_odds,
        bookmaker: {
          nom: bet.bookmaker.nom,
          surnom: bet.bookmaker.surnom,
          id: bet.bookmaker.id,
        },
      }))
    );
    if (match.id) {
      setMatchExists(true);
      setMatchId(match.id);
    } else {
      setMatchExists(false);
    }
    setIsEditing(true);
  };

  const resetInputs = () => {
    setChampionship({});
    setTeam1("");
    setTeam2("");
    setBets([{ bet: "", odds: "", stake: "", bookmaker: "" }]);
    form.resetFields(); // Réinitialisez les champs du formulaire
    form.resetFields(["championship"]);
    setIsEditing(false);
  };

  const handleSubmit = (values) => {
    message.success("Form submitted successfully!");
    resetInputs();
  };

  // Fonction pour gérer l'activation/désactivation des panels pour un pari spécifique
  const handlePanelToggle = (index) => {
    const updatedPanelStates = [...panelStates];
    updatedPanelStates[index] = !updatedPanelStates[index];
    setPanelStates(updatedPanelStates);
  };

  const updateMatches = async () => {
    setLoadingMatches(true);
    const newMatches = await fetchMatches();
    setMatches(newMatches);
    setLoadingMatches(false);
  };

  const resetInputsWithConfirmation = () => {
    Modal.confirm({
      title: "Êtes-vous sûr?",
      content: "Voulez-vous vraiment réinitialiser le tweet?",
      okText: "Oui",
      cancelText: "Non",
      onOk: () => {
        resetInputs(); // Réinitialisez seulement si l'utilisateur confirme
      },
    });
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <Header style={{ padding: "0 16px", width: "100%", textAlign: "center" }}>
        <Row justify="space-between" align="middle" style={{ width: "100%" }}>
          <Col>
            <h1 style={{ color: "white", margin: 0 }}>BetTweetr</h1>
          </Col>
          <Col>
            <Link to="/admin">
              <SettingOutlined style={{ color: "white", fontSize: "20px" }} />
            </Link>
          </Col>
        </Row>
      </Header>
      <Content
        style={{ background: "#f0f2f5", padding: "16px", textAlign: "center" }}
      >
        <div style={{ maxWidth: 800, margin: "0 auto" }}>
          <h2>Créer un tweet</h2>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            {/* Champ de sélection de championnat */}
            <Col xs={24} style={{ textAlign: "center" }}>
              <Form.Item name="championship">
                <ChampionshipSelector
                  setChampionship={handleChampionshipChange}
                  initialValue={championship.nom}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            {/* Champs d'équipe */}
            <Col xs={24}>
              <TeamInput
                team1={team1}
                team2={team2}
                handleTeamChange={handleTeamChange}
              />
            </Col>

            {/* Champs de paris */}
            <Col xs={24} style={{ width: "100%" }}>
              <h3>Bet(s)</h3>
              {bets.map((bet, index) => (
                <Form.Item
                  key={index}
                  name={`bets[${index}]`}
                  initialValue={bet}
                >
                  <BetInput
                    bet={bet.bet}
                    odds={bet.odds}
                    stake={bet.stake}
                    bookmaker={bet.bookmaker.nom}
                    isPanelActive={panelStates[index]}
                    handlePanelToggle={() => handlePanelToggle(index)}
                    handleFreeBetToggle={() => handleFreeBetToggle(index)}
                    handleBoostedOdd={() => handleBoostedOdd(index)}
                    isFreeBetActive={bet.isFreeBetActive}
                    isBoostedOdd={bet.isBoostedOdd}
                    handleBetChange={(value) => handleBetChange(index, value)}
                    handleOddsChange={(value) => handleOddsChange(index, value)}
                    handleStakeChange={(value) =>
                      handleStakeChange(index, value)
                    }
                    handleBookmakerChange={(value) =>
                      handleBookmakerChange(index, value)
                    }
                    handleRemoveBet={() => handleRemoveBet(index)}
                    onChange={(value, option) =>
                      handleBookmakerChange(
                        index,
                        isEditing ? option.data.surnom : option.data.nom
                      )
                    }
                  />
                </Form.Item>
              ))}
            </Col>

            {/* Bouton d'ajout de pari */}
            <Col xs={24} style={{ textAlign: "center" }}>
              <Button
                type="primary"
                block
                icon={<PlusOutlined />}
                onClick={handleAddBet}
                style={{ width: isMobile ? "100%" : undefined }}
              >
                Ajouter un pari
              </Button>
            </Col>

            {/* Bouton de création de tweet */}
            <Col xs={24} style={{ textAlign: "center", marginTop: "10px" }}>
              <Form.Item>
                <TweetButton
                  flag={championshipFlag}
                  championship={championship.nom}
                  championshipId={championship.id}
                  bookmakers={bets.map((bet) => bet.bookmaker.surnom)}
                  bookmakerIds={bets.map((bet) => bet.bookmaker.id)}
                  teams={{ team1, team2 }}
                  bets={bets}
                  resetInputs={resetInputs}
                  isFreeBetActive={isFreeBetActive}
                  isBoostedOdd={isBoostedOdd}
                  matchExists={matchExists}
                  matchId={matchId}
                  updateMatches={updateMatches}
                />
                <Button
                  type="primary"
                  danger
                  block
                  icon={<ReloadOutlined />}
                  onClick={resetInputsWithConfirmation}
                  style={{ marginTop: "10px" }}
                >
                  Reset tweet
                </Button>
              </Form.Item>
            </Col>
          </Form>
          {loadingMatches ? (
            <Spin />
          ) : (
            <BetCard
              matches={matches}
              onEdit={handleEdit}
              onDelete={updateMatches}
            ></BetCard>
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default MainApp;
