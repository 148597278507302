import React from "react";
import { Navigate } from "react-router-dom";
import authService from "../services/auth.service";

const AuthMiddleware = ({ children }) => {
  const isUserLoggedIn = () => {
    const currentUser = authService.getCurrentUser();
    return !!currentUser && !!currentUser.accessToken;
  };

  if (isUserLoggedIn()) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
};

export default AuthMiddleware;
