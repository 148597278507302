// AdminPageTemplate.js

import React from "react";
import { Layout, Breadcrumb } from "antd";

const { Content } = Layout;

const AdminPageTemplate = ({ breadcrumbItems, content }) => {
  const formattedBreadcrumbItems = breadcrumbItems.map((item) => ({
    title: item,
  }));

  return (
    <Layout
      style={{
        padding: "0 24px 24px",
      }}
    >
      <Breadcrumb
        style={{
          margin: "16px 0px",
        }}
        items={formattedBreadcrumbItems}
      />
      <Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
          background: "#fff",
          borderRadius: "10px",
          textAlign: "center",
        }}
      >
        {content}
      </Content>
    </Layout>
  );
};

export default AdminPageTemplate;
