import React from "react";
import { Input, InputNumber, Row, Col } from "antd";
import { EuroCircleOutlined, ThunderboltOutlined } from "@ant-design/icons";
import BookmakerSelector from "./BookmakerSelector";
import DeleteBetButton from "./BetInputComponents/DeleteBetButton";
import BetCollapseOption from "./BetInputComponents/BetCollapseOption";
import useIsMobile from "../services/useIsMobile";

const BetInput = ({
  bet,
  odds,
  stake,
  bookmaker,
  handleBetChange,
  handleOddsChange,
  handleStakeChange,
  handleBookmakerChange,
  handleRemoveBet,
  handleFreeBetToggle,
  handleBoostedOdd,
  isFreeBetActive,
  isBoostedOdd,
  index,
}) => {
  const isMobile = useIsMobile();

  const panels = [
    {
      label: "Plus d'options",
      options: [
        {
          label: "Pari gratuit",
          isChecked: isFreeBetActive,
          handleChange: () => handleFreeBetToggle(index),
          icon: (
            <EuroCircleOutlined
              style={{ color: "#00cc00", marginLeft: "8px" }}
            />
          ),
        },
        {
          label: "Côte boostée",
          isChecked: isBoostedOdd,
          handleChange: () => handleBoostedOdd(index),
          icon: (
            <ThunderboltOutlined
              style={{ color: "#FF7F00", marginLeft: "8px" }}
            />
          ),
        },
      ],
    },
  ];

  return (
    <div>
      <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
        <Col xs={24} sm={8}>
          <Input
            value={bet}
            onChange={(e) => handleBetChange(e.target.value)}
            placeholder="Pari sportif"
          />
        </Col>
        <Col xs={24} sm={4}>
          <InputNumber
            value={odds}
            onChange={handleOddsChange}
            placeholder="Côte"
            min={0}
            max={1000}
            step={0.01}
            precision={2}
            parser={(value) => value.replace(",", ".")}
            style={{ width: isMobile ? "100%" : undefined }}
            type="text"
          />
        </Col>
        <Col xs={24} sm={4}>
          <InputNumber
            value={stake}
            onChange={handleStakeChange}
            placeholder="Mise"
            addonAfter="€"
            precision={2}
            step={0.01}
            style={{ width: isMobile ? "100%" : undefined }}
            type="text"
          />
        </Col>
        <Col xs={24} sm={6}>
          <BookmakerSelector
            setBookmaker={handleBookmakerChange}
            initialValue={bookmaker}
            style={{ width: "100%" }}
          />
        </Col>
        {isMobile ? (
          <>
            <BetCollapseOption panels={panels} />
            <Col xs={24} sm={1}>
              <DeleteBetButton
                handleRemoveBet={handleRemoveBet}
                isMobile={isMobile}
              />
            </Col>
          </>
        ) : (
          <>
            <Col xs={24} sm={1}>
              <DeleteBetButton
                handleRemoveBet={handleRemoveBet}
                isMobile={isMobile}
              />
            </Col>
            <BetCollapseOption panels={panels} />
          </>
        )}
      </Row>
    </div>
  );
};

export default BetInput;
