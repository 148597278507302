import React from "react";
import { Input, Row, Col } from "antd";

const TeamInput = ({ team1, team2, handleTeamChange }) => {
  const handleChangeTeam1 = (event) => {
    handleTeamChange("team1", event);
  };

  const handleChangeTeam2 = (event) => {
    handleTeamChange("team2", event);
  };

  return (
    <Row gutter={16} align="middle">
      <Col xs={24} sm={10}>
        <Input
          placeholder="Équipe domicile"
          value={team1}
          onChange={handleChangeTeam1}
        />
      </Col>
      <Col xs={24} sm={4} style={{ textAlign: "center" }}>
        <span style={{ fontWeight: "bold" }}>vs.</span>
      </Col>
      <Col xs={24} sm={10}>
        <Input
          placeholder="Équipe extérieure"
          value={team2}
          onChange={handleChangeTeam2}
        />
      </Col>
    </Row>
  );
};

export default TeamInput;
