import api from "./api.service";
import getAuthHeader from "./auth.header.service";

export const fetchBookmakers = async () => {
  try {
    const response = await api.get("bookmakers");
    const data = response.data;

    // Trier les données par ordre alphabétique du nom
    const sortedData = data.sort((a, b) => a.nom.localeCompare(b.nom));

    return sortedData;
  } catch (error) {
    console.error("Error fetching bookmakers:", error);
    throw error; // nous propageons l'erreur pour la gérer à l'extérieur
  }
};

export const addBookmaker = async (values) => {
  try {
    await api.post("bookmakers", values, { headers: getAuthHeader() });
    return fetchBookmakers();
  } catch (error) {
    console.error("Error adding bookmaker:", error);
    throw error;
  }
};

export const deleteBookmaker = async (id) => {
  try {
    await api.delete(`bookmakers/${id}`, { headers: getAuthHeader() });
    return fetchBookmakers();
  } catch (error) {
    console.error("Error deleting bookmaker:", error);
    throw error;
  }
};

export const updateBookmaker = async (id, values) => {
  try {
    await api.put(`bookmakers/${id}`, values, { headers: getAuthHeader() });
    return fetchBookmakers();
  } catch (error) {
    console.error("Error updating bookmaker:", error);
    throw error;
  }
};
