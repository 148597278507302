import React, { useState } from "react";
import { Form, Input, Modal } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { updatePassword } from "../services/user.service";

const PasswordModal = ({ user, isVisible, onClose, onFinish }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleOk = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const updatedUser = await updatePassword(
        userId,
        currentPassword,
        newPassword
      );

      if (updatedUser) {
        onFinish(updatedUser);
        setCurrentPassword("");
        setNewPassword("");
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setCurrentPassword("");
    setNewPassword("");
    onClose();
  };

  return (
    <Modal
      title="Modifier le mot de passe"
      open={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form layout="vertical">
        <Form.Item>
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Mot de passe actuel"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Nouveau mot de passe"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PasswordModal;
